table {
  td {
    padding: 1.25rem 2rem;
    background: inherit;
    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
.my-custom-textfield .MuiInputBase-root {
  height: 30px;
  font-size: 0.75rem;
}
