@font-face {
  font-family: 'Biospace';
  src: url('/src/theme/fonts/Biospace.otf') format('woff2'), url('/src/theme/fonts/Biospace.ttf') format('woff');
  /* Outras propriedades como font-weight e font-style se aplicáveis */
}

@font-face {
  font-family: 'Afrah';
  src: url('/src/theme/fonts/AfrahDemo.otf') format('woff2'), url('/src/theme/fonts/AfrahDemo.ttf') format('woff');
  /* Outras propriedades como font-weight e font-style se aplicáveis */
}

@font-face {
  font-family: 'TimesNow-Light';
  src: url('/src/theme/fonts/TimesNow-LightItalic.woff2') format('woff2'),
    url('/src/theme/fonts/TimesNow-LightItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TimesNow-Bold';
  src: url('/src/theme/fonts/TimesNow-BoldItalic.woff2') format('woff2'),
    url('/src/theme/fonts/TimesNow-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

.MuiBox-root {
  padding: 0 !important;
}

.css-1byr0tz {
  padding: 3.5rem !important;
}
.css-1vcrhp2-MuiButtonBase-root-MuiButton-root {
  box-shadow: 'none !important';
}

/* .MuiTableCell-root {
  background-color: #fff;
  color: #000;
} */

@media print {
  .page-break {
    page-break-inside: avoid;
    break-inside: avoid;
  }
}
