/* fade.css */
.fade-enter, .fade-appear {
  opacity: 0;
}

.fade-enter-active, .fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}
